import React from "react";
import logo from "../../../../assets/images/logo/RathEG-logo.svg";
import "./DesktopHeader.scss";

const DesktopHeader = ({ path, admin, newMessages }) => {
  return (
    <nav role="navigation">
      {admin ? (
        <div className="flex-between desktop desktop-header">
          <a href="/">
            <img src={logo} alt="rath_eg logo" className="logo" />
          </a>
          <div className="admin-header">{`ADMIN - ${admin.username}`}</div>
          <a
            href="https://app.papercups.io/conversations/all"
            target="_blank"
            className={`chat-link ${newMessages ? "new-message" : ""}`}
          >
            Chat
          </a>
          <a className="header-link white" href="/users/sign_out">
            Log out admin
          </a>
        </div>
      ) : (
        <div className="flex-between desktop desktop-header">
          <a href="/">
            <img src={logo} alt="rath_eg logo" className="logo" />
          </a>
          <a
            className={`header-link ${path === "/about" ? "selected" : ""}`}
            href="/about"
          >
            <div>About us</div>
          </a>
          <a
            className={`header-link ${path === "/services" ? "selected" : ""}`}
            href="/services"
          >
            <div>Services</div>
          </a>
          <a
            className={`header-link ${path === "/news" ? "selected" : ""}`}
            href="/news"
          >
            <div>News</div>
          </a>
          <a className="header-link cream" href="/contact">
            <div>Get in touch</div>
          </a>
          <a
            className="header-link white"
            href="https://secure.tutorcruncher.com/rath-education-group/login/"
            target="_blank"
          >
            Log in
          </a>
        </div>
      )}
    </nav>
  );
};

export default DesktopHeader;
