import React, { useState } from "react";
import DesktopHeader from "./desktopHeader/DesktopHeader.js";
import MobileHeader from "./mobileHeader/MobileHeader.js";
import UseInterval from "../../hooks/useInterval.js";
import axios from "axios";
import "./Header.scss";

const unreadMessage = (message) => message.read === false;

const Header = ({ path, admin, api_key }) => {
  const [newMessages, setNewMessages] = useState(false);

  if (admin) {
    UseInterval(() => {
      axios
        .get(`https://app.papercups.io/api/v1/conversations`, {
          headers: {
            Authorization: `Bearer ${api_key}`,
          },
        })
        .then((res) => {
          let data = res.data.data;
          let unreadMessages = data.filter(unreadMessage);
          if (unreadMessages.length) {
            document.title = `(${unreadMessages.length}) Welcome to RathEG!`;
            setNewMessages(true);
          } else {
            document.title = "Welcome to RathEG!";
            setNewMessages(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 10000);
  }

  return (
    <header>
      <DesktopHeader path={path} admin={admin} newMessages={newMessages} />
      <MobileHeader admin={admin} newMessages={newMessages} />
    </header>
  );
};

export default Header;
