import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "./TutorSlider.scss";

const TutorSlider = ({ tutors }) => {
  const [numberOfSlides, setNumberOfSlides] = useState(2);

  const recalculateSlides = () => {
    if (document.body.clientWidth >= 1200) {
      setNumberOfSlides(5);
    } else if (document.body.clientWidth >= 768) {
      setNumberOfSlides(3);
    } else {
      setNumberOfSlides(1);
    }
  };

  useEffect(() => {
    recalculateSlides();
  });

  window.addEventListener("resize", recalculateSlides);

  return (
    <div className="tutor-slider">
      <div className="section-title tutors-title">Our tutors</div>
      <Swiper
        modules={[Navigation]}
        navigation
        loop={true}
        slidesPerView={numberOfSlides}
        centeredSlides={true}
      >
        {tutors?.map((tutor) => {
          return (
            <SwiperSlide key={tutor.id}>
              <img
                src={tutor.photo}
                alt={`${tutor.first_name} ${tutor.last_name[0]}.`}
              />
              <div className="name">
                {`${tutor.first_name} ${tutor.last_name[0]}.`}{" "}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default TutorSlider;
