import React from 'react';
import Intro from './intro/Intro.js';
import Pricing from './pricing/Pricing';
import { ChatWidget } from '@papercups-io/chat-widget';

const Services = () => {
  return (
    <div className="page">
      <Intro />
      <Pricing />
      <ChatWidget
        accountId="93a7fd06-fa64-445e-a8b7-380f6a2d0922"
        title="Welcome to RathEG!"
        subtitle="Ask us anything"
        newMessagePlaceholder="Start typing..."
        primaryColor="#041b2a"
        greeting="Questions? We've got the answers"
        showAgentAvailability
      />
    </div>
  );
};

export default Services;
