import React from 'react';
import IconSection from '../icon_section/IconSection';
import one from '../../../../assets/images/home/icon_one.svg';
import two from '../../../../assets/images/home/icon_two.svg';
import logo from '../../../../assets/images/logo/logo-home.svg';
import './WhoWeAre.scss';

const WhoWeAre = () => {
  const titleOne = 'HIGHLY EXPERIENCED';
  const descriptionOnePartOne =
    'Each tutor brings at least 5,000+ hours of tutoring experience';
  const descriptionOnePartTwo =
    'Every student receives a customized curriculum tailored to his or her specific student profile';
  const titleTwo = 'HIGHLY EDUCATED';
  const descriptionTwoPartOne =
    'All tutors have graduated from top universities';
  const descriptionTwoPartTwo =
    'Tutors must score in the 99th percentile for any test they teach';

  return (
    <div className="who-we-are">
      <div className="hand">
        <div className="overlay flex-column">
          <div className="image-container">
            <img className="logo" src={logo} alt="RathEG logo" />
          </div>
          {/* <div className="mobile">Tutoring</div>
          <div className="desktop">Tutoring - Test Prep</div>
          <div>Academic Mentorship</div>
          <div className="mobile">Test Prep</div> */}
        </div>
      </div>
      <div className="highly">
        <div className="section-title who-we-are-title">Who we are</div>
        <div className="flex-desktop">
          <IconSection
            icon={one}
            title={titleOne}
            descriptionOne={descriptionOnePartOne}
            descriptionTwo={descriptionOnePartTwo}
          />
          <IconSection
            icon={two}
            title={titleTwo}
            descriptionOne={descriptionTwoPartOne}
            descriptionTwo={descriptionTwoPartTwo}
          />
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
