import One from "../../../assets/images/home/slideOne.jpg";
import Two from "../../../assets/images/home/slideTwo.jpg";
import Three from "../../../assets/images/home/slideThree.jpg";
import Four from "../../../assets/images/home/slideFour.jpg";

export const assets = [
  { image: One, title: "PRIVATE HIGH SCHOOL", description: "ISEE, SSAT, HSPT" },
  { image: Two, title: "UNIVERSITY", description: "SAT, ACT, TOEFL" },
  { image: Three, title: "GRADUATE", description: "GMAT, GRE, LSAT, MCAT" },
  { image: Four, title: "ADVANCED PLACEMENT", description: "ALL SUBJECTS" },
];
