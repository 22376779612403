import React from "react";
import { assets } from "../ServicesAssets.js";
import "./ServicesMobile.scss";

const ServicesMobile = () => {
  return (
    <div className="services-mobile mobile">
      <div className="section-title services-title">Services</div>
      <div className="flex service-flex">
        {assets.map((asset) => {
          return (
            <div key={asset["title"]}>
              <div>
                <img src={asset["image"]} />
                <div className="services-title">{asset["title"]}</div>
                <div className="services-description">
                  {asset["description"]}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ServicesMobile;
