import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./AdministratorDetails.scss";

const AdministratorDetails = ({ admin, handle }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: admin.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="administrator-details"
      href={`/administrators/${admin.id}/edit`}
      ref={setNodeRef}
      style={style}
    >
      <div {...attributes} {...listeners}>
        {handle}
      </div>
      <img src={admin.photo} />
      <div className="administrator-description">
        <div>{admin.full_name}</div>
        <div>{admin.role}</div>
      </div>
      <a href={`/administrators/${admin.id}/edit`} className="edit-button">
        EDIT
      </a>
    </div>
  );
};

export default AdministratorDetails;
