import React, { useState } from 'react';
import Intro from './intro/Intro.js';
import List from './list/List';
import PostCard from './post_card/PostCard';
import PostActions from './post_actions/PostActions';
import NewsletterPopup from './newsletter_popup/NewsletterPopup.js';
import { ChatWidget } from '@papercups-io/chat-widget';
import './News.scss';

const News = ({ posts, post, admin }) => {
  const [newsletter, setNewsletter] = useState(
    localStorage.getItem('newsletter')
  );

  const closeNewsletter = () => {
    localStorage.setItem('newsletter', 'true');
    setNewsletter(true);
  };

  return (
    <div className="page ">
      {!admin && <Intro />}
      <div className={`news-post ${admin && 'admin-view'}`}>
        {posts && <List posts={posts} admin={admin} />}
        {post && (
          <div className="post-list-item">
            <PostCard post={post} showFull={true} admin={admin} />
            <PostActions admin={admin} post={post} showFull={true} />
            <a href={`/posts#post-${post.id}`}>
              <div className="action back">BACK TO LIST</div>
            </a>
          </div>
        )}
      </div>
      {!newsletter && !admin && (
        <NewsletterPopup closeNewsletter={closeNewsletter} />
      )}
      <ChatWidget
        accountId="93a7fd06-fa64-445e-a8b7-380f6a2d0922"
        title="Welcome to RathEG!"
        subtitle="Ask us anything"
        newMessagePlaceholder="Start typing..."
        primaryColor="#041b2a"
        greeting="Questions? We've got the answers"
        showAgentAvailability
      />
    </div>
  );
};

export default News;
