import React, { useState, useEffect } from 'react';
import Intro from './intro/Intro.js';
import Team from './team/Team.js';
import Educators from './educators/Educators.js';
import TutorSlider from './tutor_slider/TutorSlider.js';
import Approach from './approach/Approach.js';
import { ChatWidget } from '@papercups-io/chat-widget';

const About = ({ administrators, tutors }) => {
  const activeTutors = tutors?.results?.filter(
    (result) => result.deleted == false
  );

  return (
    <div className="page">
      <Intro />
      <Team admins={administrators} />
      <Educators />
      {activeTutors && <TutorSlider tutors={activeTutors} />}
      <Approach />
      <ChatWidget
        accountId="93a7fd06-fa64-445e-a8b7-380f6a2d0922"
        title="Welcome to RathEG!"
        subtitle="Ask us anything"
        newMessagePlaceholder="Start typing..."
        primaryColor="#041b2a"
        greeting="Questions? We've got the answers"
        showAgentAvailability
      />
    </div>
  );
};

export default About;
