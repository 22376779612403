import React from "react";
import "./Educators.scss";

const Educators = () => {
  return (
    <div className="our-educators">
      <div className="left">
        <div className="section-title educators-title">Our educators</div>
        <div className="experience">
          <div className="tutor flex">
            <div className="line"></div>
            <div className="line-text">Every tutor</div>
          </div>
          <div className="hours">5 years and 5,000 hours</div>
          <div className="veteran flex">
            <div className="line"></div>
            <div className="line-text">Veteran tutors</div>
          </div>
          <div className="hours">15 years and 15,000 hours</div>
        </div>
        <div className="text">
          <div className="text-block text-one">
            Every Rath Education Group tutor is a professional educator with at
            least 5 years and 5,000 hours of private instruction experience.
          </div>
          <div className="text-block text-two">
            Our most veteran tutors have more than 15 years and 15,000 hours of
            private instruction experience and extensive experience in
            curriculum design.
          </div>
        </div>
      </div>
      <div className="text-block right">
        Personable, engaging, and accessible, Rath Education Group tutors act as
        mentors to their students and often work with them along the full arc of
        performance testing and achieving their educational goals, from
        standardized test prep to study skills, time management, and academic
        courses.
      </div>
    </div>
  );
};

export default Educators;
