import React from "react";
import "./PricePill.scss";

const PricePill = ({ text, name }) => {
  return (
    <div className={`price-pill ${name}`}>
      <div className="text">{text}</div>
    </div>
  );
};

export default PricePill;
