import React from "react";
import PricePill from "./price-pill/PricePill.js";
import PriceCard from "./price-card/PriceCard.js";

import { prices } from "./PricingAttributes.js";
import "./Pricing.scss";

const Pricing = () => {
  return (
    <div>
      {prices.map((price) => {
        return (
          <div className="pricing-wrapper" key={price.name}>
            <PricePill text={price.pillText} name={price.name} />
            <div className={`pricing ${price.name}`} key={price.name}>
              <div className="title">{price.title}</div>
              <div className="subtitle">{price.subtitle}</div>
              <div className="price-cards">
                {price.cards.map((card) => {
                  return (
                    <PriceCard
                      card={card}
                      name={price.name}
                      key={card.description}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Pricing;
