export const prices = [
  {
    name: "package-one",
    pillText: "SINGLE HOUR - $205",
    title: "TEST PREP & COLLEGE PREP TUTORING PACKAGES",
    subtitle:
      "ACT - AP - GMAT - GRE - HSPT - IB - ISEE - LSAT - MCAT - PSAT - SAT - SSAT - TOEFL - COLLEGE COURSES",
    cards: [
      {
        hours: "12 HOURS",
        price: "$195/hr",
        description: "Best for a single test or limited time frame",
      },
      {
        hours: "24 HOURS",
        price: "$185/hr",
        description: "Comprehensive prep for any single test",
      },
      {
        hours: "36 HOURS",
        price: "$175/hr",
        description: "Best for students planning on multiple test dates",
      },
      {
        hours: "48 HOURS",
        price: "$165/hr",
        description: "Generally the maximum for any test or course",
      },
      {
        hours: "60 HOURS",
        price: "$155/hr",
        description:
          "Best for students prepping for admissions exams and multiple college level courses",
      },
    ],
  },
  {
    name: "package-two",
    pillText: "SINGLE HOUR - $165",
    title: "ACADEMIC TUTORING PACKAGES",
    subtitle: "GENERAL ACADEMIC SUBJECTS",
    cards: [
      {
        hours: "12 HOURS",
        price: "$155/hr",
        description:
          "Best for catching up in a course or to help supplement final exams",
      },
      {
        hours: "24 HOURS",
        price: "$145/hr",
        description: "Best for semester-long weekly sessions",
      },
      {
        hours: "36 HOURS",
        price: "$135/hr",
        description: "Best for annual programs or multiple courses",
      },
    ],
  },
];
