import React from 'react';
import logo from '../../../../assets/images/logo/RathEG-logo.svg';
import './MobileHeader.scss';

const MobileHeader = ({ admin, newMessages }) => {
  return (
    <nav role="navigation" className="flex-between mobile mobile-header">
      <a href="/" className="logo-wrapper">
        <img src={logo} alt="rath_eg logo" className="logo" />
      </a>

      <header>
        <input type="checkbox" id="checkbox-mobile-nav" />
        <a href="/contact" className="contact">
          <div className="contact-header">Get in touch</div>
        </a>
        <label id="burger" htmlFor="checkbox-mobile-nav">
          <div></div>
          <div></div>
          <div></div>
        </label>
        <div className={`${newMessages ? 'new-message-mobile' : ''}`}></div>
        <nav id="mobile-menu">
          <a href="/about">
            <div>About us</div>
          </a>
          <a href="/services">
            <div>Services</div>
          </a>
          <a href="/news">
            <div>News</div>
          </a>
          <a href="/contact" className="contact">
            <div>Get in touch</div>
          </a>
          {admin && (
            <a
              href="https://app.papercups.io/conversations/all"
              target="_blank"
              className={`${newMessages ? 'new-message-mobile-menu' : ''}`}
            >
              <div className="mobile-menu">Chat</div>
            </a>
          )}
          <a
            href="https://secure.tutorcruncher.com/rath-education-group/login/"
            className="log-in"
            target="_blank"
          >
            <div>Log in</div>
          </a>
        </nav>
      </header>
    </nav>
  );
};

export default MobileHeader;
