import React from "react";
import "./PriceCard.scss";

const PriceCard = ({ name, card }) => {
  return (
    <div className={`price-card ${name}`}>
      <div className="card-hours">{card.hours}</div>
      <div className="card-body">
        <div className="card-price">{card.price}</div>
        <div className="card-description">{card.description}</div>
      </div>
    </div>
  );
};

export default PriceCard;
