import React from 'react';
import './Approach.scss';

const Approach = () => {
  return (
    <div className="our-approach">
      <div className="section-title title">Our approach</div>
      <div className="content">
        <div>
          <div className="subtitle">COMMITTED</div>
          <div className="text-block">
            We create a plan of regular sessions to monitor progress toward
            content mastery. Short-term tutoring for a single test may run
            anywhere from several weeks to several months; comprehensive
            tutoring for multiple tests and/or high-level courses (such as AP
            and IB) may span a year or more.
          </div>
        </div>
        <div>
          <div className="subtitle">RIGOROUS</div>
          <div className="text-block">
            We set high expectations and reinforce to our students the critical
            nature of practice and accountability in improving performance and
            maintaining gains.
          </div>
        </div>
        <div>
          <div className="subtitle">THOROUGH</div>
          <div className="text-block">
            We have developed our strategies and techniques over decades of
            tutoring, writing curricula, and meticulously analyzing official
            practice material.
          </div>
        </div>
        <div>
          <div className="subtitle">ADAPTIVE</div>
          <div className="text-block">
            We recognize that learning styles vary, and each tutoring program is
            customized to fit the individual needs of each student.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approach;
