import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { assets } from "../ServicesAssets.js";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "./ServicesDesktop.scss";

const ServicesDesktop = () => {
  return (
    <div className="services-desktop desktop">
      <div className="section-title services-title">Services</div>
      <Swiper
        slideToClickedSlide={true}
        spaceBetween={80}
        loop={true}
        slidesPerView={3.5}
      >
        {assets.map((asset) => {
          return (
            <SwiperSlide key={asset["title"]}>
              <img src={asset["image"]} />
              <div className="service-title">{asset["title"]}</div>
              <div className="service-description">{asset["description"]}</div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default ServicesDesktop;
