import React, { useState, useMemo } from "react";
import axios from "axios";
import AdministratorDetails from "../administrator_details/AdministratorDetails.js";
import "./AdministratorList.scss";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

const AdministratorList = ({ administrators }) => {
  const [items, setItems] = useState(administrators);
  const sensors = useSensors(useSensor(PointerSensor));
  const itemIds = useMemo(() => items?.map((admin) => admin.id), [items]);

  const handle = <div className="move-button">MOVE</div>;

  const handleDragEnd = (event) => {
    const { active, over } = event;
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        arrayMove(items, oldIndex, newIndex).map((item, index) => {
          const admin = items.find((adm) => adm.id === item.id);
          return axios
            .put(`/administrators/${item.id}/reorder`, {
              administrator: {
                order: index + 1,
              },
            })

            .catch((error) => {
              alert(error.response.data.message);
            });
        });

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <div className="page">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <div className="administrator-list">
          <SortableContext items={itemIds} strategy={rectSortingStrategy}>
            {items.map((admin) => {
              return (
                <AdministratorDetails
                  admin={admin}
                  key={admin.id}
                  handle={handle}
                />
              );
            })}
          </SortableContext>
        </div>
      </DndContext>
    </div>
  );
};

export default AdministratorList;
