import React, { useState } from 'react';
import WhoWeAre from './who_we_are/WhoWeAre.js';
import ServicesDesktop from './services_desktop/ServicesDesktop.js';
import ServicesMobile from './services_mobile/ServicesMobile.js';
import PrivacyPopup from './privacy_popup/PrivacyPopup.js';
import { ChatWidget } from '@papercups-io/chat-widget';

const Home = () => {
  const [hasAcceptedPolicy, setHasAcceptedPolicy] = useState(
    localStorage.getItem('accept-policy')
  );

  const acceptPolicy = () => {
    localStorage.setItem('accept-policy', 'true');
    closePolicy();
  };

  const closePolicy = () => {
    setHasAcceptedPolicy(true);
  };

  return (
    <div className="page">
      <WhoWeAre />
      <ServicesDesktop />
      <ServicesMobile />
      {!hasAcceptedPolicy && (
        <PrivacyPopup acceptPolicy={acceptPolicy} closePolicy={closePolicy} />
      )}
      <ChatWidget
        accountId="93a7fd06-fa64-445e-a8b7-380f6a2d0922"
        title="Welcome to RathEG!"
        subtitle="Ask us anything"
        newMessagePlaceholder="Start typing..."
        primaryColor="#041b2a"
        greeting="Questions? We've got the answers"
        showAgentAvailability
      />
    </div>
  );
};

export default Home;
